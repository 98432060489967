var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_vm._m(0),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'mobile',
            {

              rules: [
                {
                  required: true,
                  message: '请输入！',
                },
              ],
            },
          ]),expression:"[\n            'mobile',\n            {\n\n              rules: [\n                {\n                  required: true,\n                  message: '请输入！',\n                },\n              ],\n            },\n          ]"}]})],1)],1)],1),_c('a-row',[_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            {
              rules: [
                {
                  required: true,
                  message: '请输入！',
                },
              ],
            },
          ]),expression:"[\n            'name',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入！',\n                },\n              ],\n            },\n          ]"}]})],1)],1)],1),_c('div',[_vm._v("注意事项")]),_c('div',[_vm._v("请连接本公司WiFi进行操作")]),_c('div',{staticClass:"center"},[_c('a-button',{attrs:{"loading":_vm.saveLoading,"htmlType":"submit","type":"primary"}},[_vm._v("验证登陆")])],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_c('div',[_vm._v("实习生人脸信息录入")])])])
}]

export { render, staticRenderFns }