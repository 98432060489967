<template>
  <div class="info">
    <div class="header">
      <div class="title">
        <div>实习生人脸信息录入</div>
      </div>
    </div>

    <a-form :form="form" @submit="handleSubmit" :label-col="{ span: 6 }" :wrapper-col="{ span: 15 }" :colon="false">



      <a-row>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="手机号">
            <a-input v-decorator="[
              'mobile',
              {

                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="姓名">
            <a-input v-decorator="[
              'name',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入！',
                  },
                ],
              },
            ]" />
          </a-form-item>
        </a-col>
      </a-row>
      <div>注意事项</div>
      <div>请连接本公司WiFi进行操作</div>
      <div class="center">
        <a-button :loading="saveLoading" htmlType="submit" type="primary">验证登陆</a-button>
      </div>
    </a-form>


  </div>
</template>

<script>
import request from "@/api/request";
function add(data) {
  return request({
    url: "/user-service/intern/login/queryUser",
    method: 'post',
    data
  });
}

export default {
  data() {
    return {
      form: this.$form.createForm(this),
      saveLoading: false,
      url: "http://10.23.67.170:8989/"
    };
  },

  mounted() {

  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const that = this;
          console.log(11)
          this.$confirm({
            title: "请确认信息无误！是否继续提交？",
            onOk() {
              that.saveLoading = true;

              add({
                ...values,
              })
                .then(res => {
                  window.localStorage.setItem("register-id", res);
                  window.location.href = `${that.url}?id=${res}&name=${values.name}&mobile=${values.mobile}`;
                })
                .finally(() => {
                  that.saveLoading = false;
                });
            }
          });
        } else {
          alert("请填写必填项");
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.info {
  padding: 16px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    height: 120px;
    margin-right: 30px;
  }

  .title {
    font-size: 24px;
    font-weight: 400;
    line-height: 38px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.center {
  margin-top: 80px;
  padding-bottom: 80px;
}

.tips {
  text-align: center;
  color: #999;
  margin-top: 16px;
}

@media screen and (max-width: 720px) {
  .header {
    flex-direction: column;

    .logo {
      height: 80px;
      margin-right: 0;
    }

    .title {
      font-size: 18px;
      font-weight: 400;
      line-height: 38px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
    }
  }
}
</style>